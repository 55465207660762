import LoginInput from './LoginInput';
import { useState } from 'react';

import styles from './PasswordInput.module.css';
type Props = {
  onChange: (value: string) => void;
  value: string;
  isLoading: boolean;
};

export default function PasswordInput({ onChange, isLoading, value }: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.PasswordInputContainer}>
      <LoginInput
        isLoading={isLoading}
        type={showPassword ? 'text' : 'password'}
        name="password"
        placeholder="password"
        onChange={onChange}
        value={value}
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className={styles.ShowPasswordButton}
      >
        {showPassword ? 'Hide' : 'Show'}
      </button>
    </div>
  );
}
