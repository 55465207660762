import { join } from 'lib/helpers';

import styles from 'components/login/LoginFormButton.module.css';
import { ReactNode } from 'react';
import Loader from 'components/common/Loader';

type Props = {
  isLoading?: boolean;
  children: ReactNode;
};

export default function LoginFormButton({ children, isLoading }: Props) {
  return (
    <button
      type="submit"
      className={join([
        styles.LoginAction,
        isLoading ? styles.LoginActionLoading : styles.LoginActionReady,
      ])}
    >
      {isLoading ? (
        <Loader backgroundColor="#aaa" foregroundColor="#888" />
      ) : (
        children
      )}
    </button>
  );
}
