import { HTMLProps } from 'react';
import { VoidCallback } from 'types/callbacks';
import { CheckIcon } from 'components/common/svgIcons';
import styles from './AccountCheckbox.module.css';

interface Props extends HTMLProps<HTMLDivElement> {
  onClick?: VoidCallback;
  showCheck?: boolean;
  checkColor?: string;
}

const AccountCheckbox = ({
  showCheck = true,
  checkColor = '#444',
  onClick,
  ...props
}: Props) => {
  return (
    <div className={styles.Checkbox} onClick={onClick} {...props}>
      {showCheck && <CheckIcon color={checkColor} />}
    </div>
  );
};

export default AccountCheckbox;
