import LayoutV2 from 'components/common/LayoutV2';
import { ReactElement, ReactNode } from 'react';
import styles from 'components/login/LoginPage.module.css';

type Props = {
  title: string;
  children: ReactNode;
  animationComponent: ReactElement;
};

export default function LoginPage({
  children,
  title,
  animationComponent,
}: Props) {
  return (
    <LayoutV2 title={title} hideMenu noPadding>
      <div className={styles.Container}>
        <div className={styles.Animation}>{animationComponent}</div>

        <div className={styles.LoginInputContainer}>
          <div className={styles.LoginInput}>
            <div className={styles.RiveLogo}>
              <img
                alt="Rive"
                src="https://cdn.rive.app/icons/rive_logo_black.svg"
              />
            </div>
            {children}
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
