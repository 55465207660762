import MartyAnimation from './MartyAnimation';
import { Fit } from '@rive-app/react-webgl2';

export default function MartyLoginAnimation() {
  return (
    <MartyAnimation
      asset={'/static/riv/marty_purple_loop.riv'}
      fit={Fit.Contain}
    />
  );
}
