import { ReactNode } from 'react';
import styles from 'components/login/LoginSubLink.module.css';

type Props = {
  href: string;
  children: ReactNode;
};

export default function LoginSubLink({ children, href }: Props) {
  return (
    <a className={styles.SubAction} href={href}>
      {children}
    </a>
  );
}
