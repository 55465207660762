import styles from './Loader.module.css';

type Props = {
  backgroundColor?: string;
  foregroundColor?: string;
};

const Loader = ({ backgroundColor, foregroundColor }: Props) => {
  const style = {
    borderColor: backgroundColor,
    borderTopColor: foregroundColor,
  };

  return <div className={styles.Loader} style={style} />;
};

export default Loader;
