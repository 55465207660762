import styles from './Spinner.module.css';

type SpinnerProps = {
  title?: string;
};

export default function Spinner({ title }: SpinnerProps) {
  return (
    <div className={styles.SpinnerContainer}>
      <div className={styles.SpinnerContent}>
        <div>
          <div className={styles.Spinner} />
          {title ? <h2 className={styles.SpinnerCaption}>{title}</h2> : <></>}
        </div>
      </div>
    </div>
  );
}
