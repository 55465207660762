import styles from 'components/login/LoginInput.module.css';
import { useWindowSize } from 'lib/deviceUtils';
import { insertIf, join } from 'lib/helpers';
import { useRef } from 'react';
import { ValueCallback } from 'types/callbacks';

type Props = {
  type?: string;
  error?: boolean;
  isLoading?: boolean;
  name: string;
  placeholder: string;
  onChange: ValueCallback<string>;
  value: string;
  straightTopLeft?: boolean;
};

export default function LoginInput({
  error,
  isLoading,
  name,
  placeholder,
  onChange,
  value,
  type = 'text',
  straightTopLeft,
}: Props) {
  const input = useRef<HTMLInputElement>(null);
  const width = useWindowSize().width;

  const scrollToFields = () => {
    width < 960 && input.current!.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <input
      ref={input}
      type={type}
      onFocus={scrollToFields}
      className={join([
        styles.LoginInput,
        styles.LoginField,
        ...insertIf(Boolean(error), styles.LoginFieldError),
        ...insertIf(Boolean(isLoading), styles.LoginFieldLoading),
      ])}
      name={name}
      placeholder={placeholder}
      onChange={(event) => onChange(event.target.value)}
      value={value}
      style={straightTopLeft ? { borderRadius: '0px 15px 0px 0px' } : undefined}
    />
  );
}
