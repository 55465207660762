import { PasswordError } from './types';
import styles from './PasswordErrors.module.css';

type Props = {
  errors: PasswordError[];
  dark?: boolean;
};

function getPasswordError(error: PasswordError) {
  if (error === 'too-long') {
    return 'Password can be at most 1024 characters';
  }
  if (error === 'too-short') {
    return 'be at least 10 characters';
  }
  if (error === 'missing') {
    return 'Missing password';
  }
  if (error === 'too-many-repeating-characters') {
    return 'not contain more than 2 identical characters in a row';
  }
  if (error === 'missing-lowercase') {
    return 'contain at least one lowercase letter';
  }
  if (error === 'missing-uppercase') {
    return 'contain at least one uppercase letter';
  }
  if (error === 'missing-number') {
    return 'contain at least one number';
  }
  if (error === 'missing-special') {
    return 'contain at least one special character';
  }
  return '';
}

export default function PasswordErrors({ errors, dark = false }: Props) {
  if (errors.length === 0) {
    return null;
  }

  return (
    <>
      <div className={dark ? styles.PasswordMustDark : styles.PasswordMust}>
        Password must:
      </div>
      <ul className={styles.PasswordErrors}>
        {errors.map((error) => (
          <li key={error} className={styles.PasswordError}>
            {getPasswordError(error)}
          </li>
        ))}
      </ul>
    </>
  );
}
