import { useRouter } from 'next/router';
import React, { FunctionComponent, useState } from 'react';
import styles from './FormFields.module.css';
import LoginFields from './LoginFields';
import SignupFields from './SignupFields';
import { join } from 'lib/helpers';
import { ErrorResponse } from './types';
import { getNewSignupUrl } from 'lib/utils';
import { VoidCallback } from 'types/callbacks';

enum Provider {
  Google = 'google',
  Facebook = 'facebook',
}

type FormFieldProps = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  serverError?: string;
  clearServerError?: VoidCallback;
};

const showLogin = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  // default to login page.
  return (urlParams.get('tab') || 'login') === 'login';
};

const setSearchParam = (searchParam: string, tabName: string) => {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(searchParam, tabName);
    const newRelativePathQuery =
      window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newRelativePathQuery);
  }
};

const FormFields: FunctionComponent<FormFieldProps> = ({
  setTitle,
  serverError,
}) => {
  const [loginTab, setLoginTab] = useState(showLogin());
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [optInToEmails, setOptInToEmails] = useState(true);
  const [errorString, setErrorString] = useState<string | null | undefined>(
    serverError
  );
  const router = useRouter();

  const { NEXT_PUBLIC_API: domain } = process.env;
  let referer = router?.query?.redirect;

  if (typeof referer === 'string') {
    try {
      const url = new URL(referer);
      const domain = process.env.NEXT_PUBLIC_SITE_DOMAIN ?? 'rive.app';
      if (!url.host.endsWith(domain)) {
        referer = '';
      }
    } catch (err) {
      // URL constructor throws if parameter is invalid
      // most probably because it doesn't contain a hostname.

      // Let's check that it's only a pathname - that is:
      // - starts with a '/'
      // - it contains anything but a space or a '/'
      // - it can end with a '/'
      const pathnameCheck = referer.match(/^(\/[^/ ]+)+\/?$/i);
      if (!pathnameCheck) {
        // No match is an invalid pathname.
        referer = '';
      }
    }
  } else {
    referer = '';
  }

  function onOAuth(social: Provider) {
    if (!loginTab) {
      const errors: ErrorResponse = {};
      setErrorString(null);
      if (Object.keys(errors).length) {
        return;
      }
    }

    const params: any = {
      onlySignin: loginTab,
    };

    if (loginTab) {
      params.redirect = referer ? referer : '/profile';
    } else {
      params.redirect = referer ? referer : getNewSignupUrl(social);
      params.opt_in_to_emails = optInToEmails;
    }

    const paramsStr = new URLSearchParams(params).toString();

    // e.g. :
    // 'https://../oauth/google/start?onlySignin=[true|false]&redirect=[http://...]
    const url = `${domain}/oauth/${social}/start?${paramsStr}`;

    window.location.assign(url);
  }

  return (
    <>
      <p className={styles.LoginCaption}>
        Express login via Google and Facebook
      </p>
      <div className={styles.SocialLogin}>
        <div onClick={() => onOAuth(Provider.Google)}>
          Google
          <img alt="Google" src="/static/signin-google.png" />
        </div>
        <div onClick={() => onOAuth(Provider.Facebook)}>
          Facebook
          <img alt="Facebook" src="/static/signin-facebook.png" />
        </div>
      </div>
      <div className={styles.LoginDivider}></div>

      <div className={styles.LoginSignupTabs}>
        <button
          className={join([
            styles.Tab,
            styles.Login,
            loginTab ? styles.Selected : styles.Unselected,
          ])}
          onClick={() => {
            if (!loginTab) {
              setTitle('Login');
              setSearchParam('tab', 'login');
              setLoginTab(true);
            }
          }}
        >
          <div>Log in</div>
        </button>
        <button
          className={join([
            styles.Tab,
            styles.Signup,
            !loginTab ? styles.Selected : styles.Unselected,
          ])}
          onClick={() => {
            if (loginTab) {
              setTitle('Register');
              setSearchParam('tab', 'register');
              setLoginTab(false);
            }
          }}
        >
          <div>Sign up</div>
        </button>
        <div className={styles.Filler}>
          <div
            style={{
              borderRadius: `0px 0px 0px ${!loginTab ? 10 : 0}px`,
            }}
          />
        </div>
      </div>
      {loginTab ? (
        <LoginFields
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          referer={referer}
          errorString={errorString}
          setErrorString={setErrorString}
        />
      ) : (
        <SignupFields
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          optInToEmails={optInToEmails}
          setOptInToEmails={setOptInToEmails}
          errorString={errorString}
          setErrorString={setErrorString}
        />
      )}
    </>
  );
};

export default FormFields;
