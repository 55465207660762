import { useRouter } from 'next/router';
import { useState, useContext, useEffect } from 'react';
import Spinner from 'components/common/Spinner';
import MartyLoginAnimation from 'components/about/animations/MartyLoginAnimation';
import FormFields from 'components/login/FormFields';
import LinkAccounts from 'components/login/LinkAccounts';
import { UserContext } from 'contexts/user';

import LoginPage from 'components/login/LoginPage';

export default function Login() {
  const router = useRouter();
  const { user, userLoaded } = useContext(UserContext);
  const [pageTitle, setPageTitle] = useState('Login');

  const [serverError, setServerError] = useState<string | undefined>();

  const oauthError = user?.oauth?.error;

  useEffect(() => {
    setServerError((error) => (error === undefined ? oauthError : error));
  }, [oauthError]);

  if (userLoaded && user?.signedIn) {
    const redirect = router.query.redirect;
    if (typeof redirect == 'string') {
      window.location.assign(redirect);
    } else {
      window.location.assign('/');
    }

    return <></>;
  }

  function loginContents() {
    const isLinkingAccounts =
      user?.oauth?.nm !== undefined && user?.oauth?.em !== undefined;
    if (userLoaded === false) {
      return <Spinner />;
    } else if (isLinkingAccounts) {
      const { oauth } = user;
      return <LinkAccounts email={oauth!.em} provider={oauth!.nm} />;
    } else {
      return (
        <FormFields
          setTitle={setPageTitle}
          serverError={serverError}
          clearServerError={() => {
            setServerError(undefined);
          }}
        />
      );
    }
  }

  return (
    <LoginPage title={pageTitle} animationComponent={<MartyLoginAnimation />}>
      {loginContents()}
    </LoginPage>
  );
}
