import { fetchUrlWithCookies } from 'contexts/user';
import { FunctionComponent, useState } from 'react';
import { mutate } from 'swr';
import styles from './LinkAccounts.module.css';

type LinkAccountProps = {
  email: string;
  provider: string;
};

const LinkAccounts: FunctionComponent<LinkAccountProps> = ({
  email,
  provider,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onLink = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    await fetchUrlWithCookies('/api/linkAccount', {
      redirect: 'manual',
    });
    window.location.assign('/');
  };

  const onCancel = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const canceled = await fetchUrlWithCookies('/api/cancelLink');
    const success = canceled.status;
    if (success === 200) {
      mutate('/api/me', { signedIn: false });
    }
    setIsLoading(false);
    window.location.assign('/login');

    return;
  };

  return (
    <div className={styles.LoginLinkText}>
      <p>
        {'A rive account associated with '}
        <span className={styles.LoginLinkHighlight}>{email}</span>{' '}
        {' already exists. Do you want to link it with your '}
        <span className={styles.LoginLinkHighlight}>
          {provider.charAt(0).toUpperCase() + provider.slice(1)}
        </span>{' '}
        account?
      </p>
      <div className={styles.SocialLogin}>
        <div
          onClick={onLink}
          className={!isLoading ? styles.LoginLink : styles.LoginLinkDisabled}
        >
          Link Account
        </div>
        <div
          onClick={onCancel}
          className={
            !isLoading ? styles.LoginLinkCancel : styles.LoginLinkCancelDisabled
          }
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default LinkAccounts;
