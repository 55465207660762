import { useRive, Alignment, Fit, Layout } from '@rive-app/react-webgl2';

type Props = {
  asset: string;
  fit?: Fit;
};

export default function MartyAnimation({ asset, fit = Fit.Cover }: Props) {
  const params = {
    src: asset,
    stateMachines: ['Loop'],
    layout: new Layout({
      fit,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  };

  const { RiveComponent } = useRive(params);

  return <RiveComponent />;
}
