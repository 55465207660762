import { directSignUpCompleted, setUserId } from 'lib/analytics';
import { getPrivacyPolicyPath, getToSPath } from 'lib/community';
import { getNewSignupUrl } from 'lib/utils';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useState } from 'react';
import LoginForm from './LoginForm';
import LoginFormButton from './LoginFormButton';
import LoginInput from './LoginInput';
import styles from './SignupFields.module.css';
import { ErrorResponse, PasswordError } from './types';
import { checkPasswordForErrors, getAPIErrorString } from './utils';
import AccountCheckbox from 'components/account/AccountCheckbox';
import PasswordErrors from './PasswordErrors';
import PasswordInput from './PasswordInput';
import featureFlags from 'lib/featureFlags';

type SignupFieldsProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  optInToEmails: boolean;
  setOptInToEmails: React.Dispatch<React.SetStateAction<boolean>>;
  errorString?: string | null;
  setErrorString: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
};

const SignupFields: FunctionComponent<SignupFieldsProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  optInToEmails,
  setOptInToEmails,
  errorString,
  setErrorString,
}) => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [recheckPassword, setRecheckPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordError[]>([]);

  const { NEXT_PUBLIC_API: domain } = process.env;

  // TODO: extract this into shared utils

  async function onSignup() {
    if (isLoading) {
      return;
    }

    if (email.length <= 0) {
      return setErrorString(getAPIErrorString({ email: 'missing' }));
    }

    if (password.length <= 0) {
      return setErrorString(getAPIErrorString({ password: 'missing' }));
    } else {
      if (featureFlags.passwordComplexity()) {
        const passwordErrors = checkPasswordForErrors(password);
        if (passwordErrors.length > 0) {
          setPasswordErrors(passwordErrors);
          setRecheckPassword(true);
          return;
        }
      }
    }

    setErrorString(null);
    setPasswordErrors([]);
    setRecheckPassword(false);

    if (!domain) {
      throw new Error('Cannot call the API: env not set');
    }
    setLoading(true);
    const body = {
      password: password,
      email: email,
      opt_in_to_emails: optInToEmails,
    };

    const register = await fetch(`${domain}/register`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
    });
    const jsonResponse = await register.json();
    if (!register.ok) {
      const errorResponse = jsonResponse as ErrorResponse;
      setErrorString(getAPIErrorString(errorResponse));
    } else {
      setUserId(jsonResponse.ownerId);
      directSignUpCompleted();
      const redirect = router?.query?.redirect;
      if (typeof redirect === 'string') {
        window.location.assign(redirect);
      } else {
        window.location.assign(getNewSignupUrl('direct'));
      }
    }
    setLoading(false);
  }

  return (
    <>
      <LoginForm onSubmit={onSignup} errorString={errorString}>
        <LoginInput
          isLoading={isLoading}
          name="email"
          placeholder="email"
          onChange={(value) => {
            setErrorString(null);
            setEmail(value);
          }}
          value={email}
        />
        <PasswordInput
          isLoading={isLoading}
          onChange={(value) => {
            setErrorString(null);
            setPassword(value);
            if (value && recheckPassword) {
              const passwordErrors = checkPasswordForErrors(value);
              if (passwordErrors) {
                setPasswordErrors(passwordErrors);
              }
            }
          }}
          value={password}
        />
        <LoginFormButton isLoading={isLoading}>Sign up</LoginFormButton>

        {passwordErrors.length > 0 && (
          <PasswordErrors errors={passwordErrors} />
        )}
      </LoginForm>
      <div className={styles.EmailOptIn}>
        <AccountCheckbox
          onClick={() => setOptInToEmails(!optInToEmails)}
          showCheck={optInToEmails}
          checkColor={'#242424'}
          style={{ backgroundColor: '#f1f1f1' }}
        />
        <p>Send me emails with the latest updates from Rive</p>
      </div>
      <div className={styles.Legal}>
        By signing up you agree to our{' '}
        <a href={getToSPath()}>Terms of Service</a> and{' '}
        <a href={getPrivacyPolicyPath()}>Privacy Policy</a>
      </div>
    </>
  );
};

export default SignupFields;
