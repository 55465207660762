import React, { FunctionComponent, useState } from 'react';
import { ErrorResponse } from './types';
import { getAPIErrorString } from './utils';
import LoginForm from './LoginForm';
import LoginInput from './LoginInput';
import LoginFormButton from './LoginFormButton';
import LoginSubLink from './LoginSubLink';
import { logLoginError } from 'lib/analytics';
import featureFlags from 'lib/featureFlags';
import { VoidCallback } from 'types/callbacks';
import PasswordInput from './PasswordInput';

type LoginFieldsProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  referer: string;
  errorString?: string | null;
  setErrorString: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  clearErrorString?: VoidCallback;
};

const LoginFields: FunctionComponent<LoginFieldsProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  errorString,
  setErrorString,
  referer,
}) => {
  const [isLoading, setLoading] = useState(false);

  const { NEXT_PUBLIC_API: domain } = process.env;

  const onLogin = async () => {
    if (isLoading) {
      return;
    }

    if (email.length <= 0) {
      const errorString = getAPIErrorString({ email: 'missing' });
      return setErrorString(errorString);
    }
    if (password.length <= 0) {
      const errorString = getAPIErrorString({ password: 'missing' });
      return setErrorString(errorString);
    }
    setErrorString(null);

    if (!domain) {
      throw new Error('Cannot call the API: env not set');
    }
    setLoading(true);
    const body = { username: email, password: password };
    const signedIn = await fetch(`${domain}/signin`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
    });
    const jsonResponse = await signedIn.json();

    if (!signedIn.ok) {
      const errorResponse = jsonResponse as ErrorResponse;
      setLoading(false);
      setErrorString(getAPIErrorString(errorResponse));
      logLoginError(errorResponse);
    } else {
      // All good, let's go on.
      if (referer && typeof referer === 'string' && referer.length > 0) {
        window.location.assign(referer);
      } else {
        window.location.assign('/profile');
      }
    }
  };

  return (
    <>
      <LoginForm onSubmit={onLogin} errorString={errorString}>
        <LoginInput
          isLoading={isLoading}
          name="email"
          placeholder="email or username"
          onChange={(value) => {
            setErrorString(null);
            setEmail(value);
          }}
          value={email}
          straightTopLeft
        />
        <PasswordInput
          isLoading={isLoading}
          onChange={(value) => {
            setErrorString(null);
            setPassword(value);
          }}
          value={password}
        />
        <LoginFormButton isLoading={isLoading}>Log in</LoginFormButton>
      </LoginForm>
      {featureFlags.ssoLogin() ? (
        <LoginSubLink href="/login/sso">Log in with SSO</LoginSubLink>
      ) : null}
      <LoginSubLink href="/forgot">Forgot password?</LoginSubLink>
    </>
  );
};

export default LoginFields;
