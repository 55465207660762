import { ReactNode } from 'react';

import styles from 'components/login/LoginForm.module.css';
import { VoidCallback } from 'types/callbacks';

type Props = {
  errorString?: string | null;
  children: ReactNode;
  onSubmit: VoidCallback;
};

export default function LoginForm({ children, onSubmit, errorString }: Props) {
  return (
    <div className={styles.Container}>
      <form
        className={styles.InputFields}
        onSubmit={(event) => {
          event?.preventDefault();
          onSubmit();
        }}
      >
        {children}
        {errorString && <div className={styles.Error}>{errorString}</div>}
      </form>
    </div>
  );
}
